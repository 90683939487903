import * as React from "react";

const CvCard = (props) => {
  return (
    <div className="about-cv-block-entry">
      <h2 className="cv">{props.heading}</h2>
      <p className="medium-text cv-text">{props.content}</p>
      <p className="project-tag cv-tag">{props.date}</p>
    </div>
  );
};

export default CvCard;
