import * as React from "react";
import "../styles/global.css";
import HamburgerMenu from "./hamburgerMenu";
import Menu from "../components/menu";

const Layout = (props) => {
  return (
    <div className="main">
       <Menu></Menu>
      <div className="main-content">
        <HamburgerMenu></HamburgerMenu>
        <div className="main-header">{props.header}</div>
        <div className="main-content-content">{props.content}</div>
      </div>
    </div>
  );
};

export default Layout;
