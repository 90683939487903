import * as React from "react";
import { Link } from "gatsby";

const HamburgerMenu = () => {
  return (
    <nav id="hamburger-menu" role="navigation">
      <div id="menuToggle">
        <input type="checkbox" />

        <span></span>
        <span></span>
        <span></span>

        <ul id="menu">
          <li>
            <Link to="../">Home</Link>
          </li>
          <li>
            <Link to="../projects">Projects</Link>
          </li>
          <li>
            <Link to="../teaching">Teaching</Link>
          </li>
          <li>
            <Link to="../about">About</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default HamburgerMenu;
