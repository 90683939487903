import * as React from "react";
import "../styles/global.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer-connections">
        <a className="connections-link" href="mailto:mr.tosun@gmail.com">
          Email
        </a>
        <a
          className="connections-link"
          href="https://www.behance.net/merttosun"
        >
          Behance
        </a>
        <a className="connections-link" href="https://twitter.com/tosunmert">
          Twitter
        </a>
      </div>
      <p>Mert Tosun © 2024</p>
    </footer>
  );
};

export default Footer;
