import React from "react";
import "../styles/global.css";
import { Link } from "gatsby";

const Menu = (props) => {
  return (
    <nav className="main-navmenu">
    <h1 className="home-header">Mert Tosun</h1>
    <ul>
      <li>
        <Link className="main-navmenu-item" to="../">
          Home
        </Link>
      </li>
      <li>
        <Link className="main-navmenu-item" to="../projects">
          Projects
        </Link>
      </li>
      <li>
        <Link className="main-navmenu-item" to="../teaching">
          Teaching
        </Link>
      </li>
      <li>
        <Link className="main-navmenu-item" to="../about">
          About
        </Link>
      </li>
    </ul>
  </nav>
  );
};

export default Menu;
