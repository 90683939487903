import * as React from "react";
import Layout from "../components/layout";
import Footer from "../components/footer";
import CvCard from "../components/cvCard";
import scrollTo from "gatsby-plugin-smoothscroll";
import CV from "../images/Mert Tosun CV.pdf";
import { navigate } from "gatsby";

const About = () => {
  return (
    <div className="about">
      <Layout
        content={
          <div>
            <div className="home-head" id="cv-head">
              <h1>Hi!</h1>
              <p className="about-bio medium-text">
                I'm a design educator and a PhD candidate.
                <br></br>
                <br></br>
                I've been teaching various design courses at TOBB ETU's
                Department of Industrial Design for seven years, with a focus on
                user experience, design methods, and computer-aided design. You
                can find the full list of courses I've taught on the teaching
                page.
                <br></br>
                <br></br>
                I'm pursuing a Ph.D. focused on how game design can promote
                pro-environmental behavior. As part of my research, I was
                involved in game design projects at Carnegie Mellon University's
                Human-Computer Interaction Institute during the 2021-2022
                academic year, supported by a Fulbright VSR scholarship.
              </p>
              <div className="about-buttons">
                <button onClick={() => scrollTo(".about-cv")}>
                  View my CV
                </button>

                <a id="about-mail-link" href="mailto:mr.tosun@gmail.com">
                  Start a conversation
                </a>
              </div>
            </div>
          </div>
        }
      ></Layout>
      <div className="about-cv">
        <h1 className="cv-header">Mert Tosun</h1>
        {/* EDUCATION BLOCK */}
        <div className="about-cv-block">
          <div className="about-cv-block-category">EDUCATION</div>
          <div className="about-cv-block-entries">
            <CvCard
              heading="Carnegie Mellon University - Visiting Researcher "
              content="Human-Computer Interaction Institute (Fulbright VSR Scholarship) "
              date="2021 - 2022"
            ></CvCard>
            <CvCard
              heading="Middle East Technical University / PhD"
              content="Department of Industrial Design"
              date="2017 - "
            ></CvCard>
            <CvCard
              heading="TU Delft -  METU / MSc"
              content="Design Research for Interaction Joint Programme"
              date="2015 - 2017"
            ></CvCard>
            <CvCard
              heading="METU / BSc"
              content="Industrial Design"
              date="2007 - 2011"
            ></CvCard>
          </div>
        </div>

        {/* WORK EXPERIENCE BLOCK */}
        <div className="about-cv-block">
          <div className="about-cv-block-category">WORK EXPERIENCE</div>
          <div className="about-cv-block-entries">
            <CvCard
              heading="Design Lecturer"
              content="TOBB University of Technology and Economics "
              date="2017 - "
            ></CvCard>
            <CvCard
              heading="UX / UI Consultant"
              content="Oruba Medical"
              date="2019 - 2021"
            ></CvCard>
            <CvCard
              heading="Industrial Designer"
              content="MEDEC (Medical Equipment Design Center)"
              date="2012 - 2014"
            ></CvCard>
            <CvCard
              heading="Industrial Designer"
              content="Designnobis"
              date="2011 - 2012"
            ></CvCard>
          </div>
        </div>

        {/* PUBLICATIONS BLOCK */}
        <div className="about-cv-block">
          <div className="about-cv-block-category">PUBLICATIONS</div>
          <div className="about-cv-block-entries">
            <p className="medium-text cv-text publication">
              Tosun, M., Öztoprak, A., & Berkman, A. E. (2019).{" "}
              <i>
                Project Process Cards: A Self-Evaluation Tool for Design Studio.
              </i>{" "}
              Proceedings of DRS Learn X Design 2019: Insider Knowledge.
            </p>
            <p className="medium-text cv-text publication">
              Tosun, M. (2017).{" "}
              <i>
                Strategies for design interventions that promote prosocial
                behavior for littering.
              </i>{" "}
              (Master's thesis).
            </p>
            <p className="medium-text cv-text publication">
              Tosun, M., Gürtekin B. (2018).{" "}
              <i>
                Redesigning the Registration Process of Syrian Refugees in
                Ankara
              </i>{" "}
              [Poster Presentation]. 3rd National Design Conference, Ankara,
              Turkey. (in Turkish)
            </p>
            <p className="medium-text cv-text publication">
              Işık Tosun, H., Tosun, M. (2016).{" "}
              <i>
                Discussing the Designer's Role in Small Scale Product
                Development Processes
              </i>{" "}
              [Poster Presentation]. 2nd National Design Conference, Ankara,
              Turkey. (in Turkish)
            </p>
          </div>
        </div>

        {/* AWARDS BLOCK */}
        <div className="about-cv-block">
          <div className="about-cv-block-category">AWARDS</div>
          <div className="about-cv-block-entries">
            <CvCard
              content="Third place in Automotive Component Design Competition"
              date="4th Future of Automotive Design Competition - 2015 (Team member. Toğrul Tagizade)"
            ></CvCard>
            <CvCard
              content="Second Place in Build Themed Toy Design Competition"
              date="Turkish Chamber of Architects - 2010"
            ></CvCard>
            <CvCard
              content="Honorable Mention in Packaging Design Competition"
              date="Turkish Packaging Association - 2010"
            ></CvCard>
          </div>
        </div>
        <div className="about-cv-buttons">
          <a className="download-button" href={CV}>
            Download PDF
          </a>
          <button className="cv-button" onClick={() => scrollTo(".about")}>
            Go to top
          </button>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default About;
